<script>
import { ref } from "vue";
import useVuelidate from "@vuelidate/core";

// import DropZone from "@/components/widgets/dropZone";
// import datepicker from "vue3-datepicker";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
import loaderProcessVue from '../../../components/widgets/loaderProcess.vue';
/**
 * Projects-create component
 */
export default {
  page: {
    title: "Create New Project",
    meta: [{ name: "description", content: appConfig.description }],
  },
  setup() {
    let dropzoneFile = ref("");
    const drop = (e) => {
      dropzoneFile.value = e.dataTransfer.files[0];
    };
    const selectedFile = () => {
      dropzoneFile.value = document.querySelector(".dropzoneFile").files[0];
    };
    return { dropzoneFile, drop, selectedFile, v$: useVuelidate() };
  },
  components: {
    // DropZone,
    // datepicker,
    Layout,
    PageHeader,
    loaderProcessVue,
  },
  data() {
    return {
      categories: [],
      categorie: [],
      video: {
        description: "",
        annee: "",
        duree: "",
        titre: "",
      },
      channelId: "",
      tags: [],
      tagList: [],
      channels: [],
      videos: [],
      programmes: [],
      initialTags: [],
      title: "Modifier la Vidéo",
      items: [
        {
          text: "Vidéos",
          href: "/projects/videos",
        },
        {
          text: "Modifier",
          active: true,
        },
      ],
      // dropzoneOptions: {
      //   url: "https://httpbin.org/post",
      //   thumbnailWidth: 150,
      //   maxFilesize: 0.5,
      //   headers: { "My-Awesome-Header": "header value" },
      // },
      // daterange: ref(new Date()),
    };
  },
  methods: {

    addTags(event) {
      event.preventDefault();
      const val = event.target.value.trim();
      if (val.length > 0 && !this.tags.includes(val)) {
        this.tags.push(val);
        event.target.value = '';
      } else {
        alert("Le tag est deja present")
      }
    },
    removeTags(index) {
      this.tags.splice(index, 1)
    },
    removeLastTag(event) {
      if (event.target.value.length === 0) {
        this.removeTags(this.tags.length - 1)
      }
    },



    onFileSeelcted(event) {
      this.selectedFile = event.target.files[0]
    },


    imgVideoSelect(event) {
      this.imgSelect = event.target.files[0]
    },

    updateChannel() {
      this.process = true

      Api.putFormData("/streamvod/rest/channel/update/" + this.$route.params.id + "?channel_description=" + this.description + "&channel_name=" + this.titre + "&channel_url=" + this.url, {
        file: this.imagePSelectFile
      })
        .then(() => {
          this.process = false
          this.$router.push("/projects/channels");
        }).catch((error) => {
          this.process = false
          Erreur.gestionErreur(error.message)
        })
    },

    getChannel() {
      this.process = true


      Api.get("/streamvod/api/channel/channel-all")
        .then((response) => {
          this.process = false
          this.channels = response.data.content
        }).catch((error) => {
          this.process = false
          Erreur.gestionErreur(error.message)
        })
    },
  },
  mounted() {


    Api.get("/streamvod/rest/categories/all")
      .then((res) => {
        this.categories = res.data.content
      }).catch((error) => {
        this.process = false
        Erreur.gestionErreur(error.message)
      })


    Api.get("/streamvod/rest/tags/all")
      .then((res) => {
        this.tagList = res.data.content
      }).catch((error) => {
        this.process = false
        Erreur.gestionErreur(error.message)
      })


    this.getChannel();


    Api.get('/streamvod/rest/videos/' + this.$route.params.id)
      .then((response) => {
        this.video.description = response.data.content.description
        this.video.titre = response.data.content.title
        this.video.annee = response.data.content.years
        this.video.duree = response.data.content.duration
        this.channelId = response.data.content.channel.id
        this.categorie = response.data.content.categorie.name
        this.initialTags = response.data.content.tags
        this.initialTags.forEach((items) => {
          this.tags.push(items.name)
        })

      }).catch((error) => {
        this.process = false
        Erreur.gestionErreur(error.message)
      })
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <!-- <div class="select-action">
            <p :class="{borderB: plannif === 'onePlaning'}"  @click="addOnePlanning"><strong>Planifiction</strong></p>
            <p :class="{borderB: plannif === 'longPlaning'}"  @click="addLongPlanning"><strong>Planification longue durée</strong></p>
          </div> -->


      <div class="card" style="padding: 25px; width: 80%; margin-left: auto; margin-right: auto; border-radius: 15px;">

        <div class="card-body">
          <h4 class="card-title mb-4">{{ $t("pages.updateVideos.formulaire.title") }}</h4>
            <div class="card-body">
              <form>
                <div class="form-group row mb-2">
                  <div class="col-lg-6">
                    <label for="projectname" class="col-form-label">{{ $t("pages.updateVideos.formulaire.titre.label")
                    }}</label>
                    <input id="projectname" name="projectname" type="text" class="form-control" v-model="video.titre"
                      :placeholder="$t('pages.updateVideos.formulaire.titre.placeholder')" />
                  </div>

                  <div class="col-lg-6">
                    <label class=" col-form-label">{{ $t("pages.updateVideos.formulaire.channel.label") }}</label>
                    <div class="col-md-12">
                      <select class="form-control" v-model="channelId">
                        <option :value="channel.id" v-for="channel in channels" :key="channel.id">{{ channel.channel_name
                        }}</option>
                      </select>
                    </div>
                  </div>
                </div>




                <div class="row mb-2">

                  <div class="form-group col-lg-6">
                    <label class="col-form-label">{{ $t('pages.updateVideos.formulaire.years.label') }}</label>
                    <div class="col-lg-12">
                      <input id="projectname" name="projectname" type="text" v-model="video.annee" class="form-control"
                        :placeholder="$t('pages.updateVideos.formulaire.years.placeholder')" />
                    </div>
                  </div>

                  <div class="form-group col-lg-6">
                    <label for="projectbudget" class="col-form-label">{{ $t('pages.updateVideos.formulaire.duree.label')
                    }}</label>
                    <div class="col-lg-12">
                      <input id="projectbudget" name="projectbudget" type="text" v-model="video.duree"
                        :placeholder="$t('pages.updateVideos.formulaire.duree.placeholder')" class="form-control" />
                    </div>
                  </div>
                </div>


                <div class="row mb-2">
                  <div class="form-group col-lg-6 ">
                    <label for="projectname" class="col-form-label">{{ $t('pages.updateVideos.formulaire.files.image')
                    }}</label>
                    <div class="col-lg-12">
                      <input id="projectname" name="projectname" type="file" @change="imgVideoSelect($event)"
                        class="form-control" placeholder="Entre le lien de la vidéo..." accept=".png, .jpeg, .jpg" />
                    </div>
                  </div>

                  <div class="form-group col-lg-6">
                    <label for="projectbudget" class="col-form-label ">{{ $t('pages.updateVideos.formulaire.files.video')
                    }}</label>
                    <div class="col-lg-12">
                      <input id="projectbudget" name="projectbudget" type="file" @change="onFileSeelcted($event)"
                        class="form-control" accept=".MOV, .AVI, .MP4" />
                    </div>
                  </div>
                </div>

                <div class="mb-2 row">
                  <div class="col-lg-6">
                    <label class="col-form-label">{{ $t('pages.updateVideos.formulaire.categ.label') }}</label>
                    <div class="col-md-12">
                      <select class="form-control" v-model="categorie">
                        <option value="">Sélectionner une catégoeie</option>
                        <option v-for="categ in categories" :key="categ.id" :value="categ.name">{{ categ.name }}</option>

                      </select>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <label class=" col-form-label">{{ $t('pages.updateVideos.formulaire.tags.label') }}</label>

                    <div class="form-control">
                      <div v-for="(tag, index) in tags" :key="index" class="tags-input_tag">
                        <span @click="removeTags(index)"><i class="mdi mdi-close-thick"></i></span>
                        {{ tag }}
                      </div>
                      <select @keydown="addTags" @keydown.delete="removeLastTag" class="tags-input_text"
                        @change="addTags">
                        <option value="">{{ $t('pages.updateVideos.formulaire.tags.placeholder') }}</option>
                        <option aria-placeholder="okokok" v-for="tagL in tagList" :key="tagL.id" :value="tagL.name">{{
                          tagL.name }}</option>
                      </select>
                      <!-- <input type="text" :placeholder="$t('pages.videos.formulaire.tags.placeholder')" @keydown="addTags" @keydown.delete="removeLastTag" class="tags-input_text"> -->
                    </div>
                  </div>
                </div>

                <div class="form-group row mb-2">
                  <label for="projectdesc" class="col-form-label">{{ $t('pages.updateVideos.formulaire.description.label')
                  }}</label>
                  <div class="col-lg-12">
                    <textarea id="projectdesc" class="form-control" rows="3" v-model="video.description"
                      :placeholder="$t('pages.updateVideos.formulaire.description.placeholder')"></textarea>
                  </div>
                </div>

              </form>

            </div>
          <div class="row">
            <div class="col-lg-10">
              <button type="submit" class="btn c2play-primary" @click="updateChannel">
                {{ $t('updateButton') }}
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
    <loaderProcessVue v-if="process == true"></loaderProcessVue>
    <!-- end row -->
  </Layout>
</template>

<style>
.select-action {
  display: flex;
  justify-content: space-between;
}

.select-action p {
  text-align: center;
  width: 100%;
  font-size: 1.3em;
  cursor: pointer;
  transition: all 0.5s ease;
}

.select-action p:hover {
  transform: scale(1.2);
}

.borderB {
  border-bottom: 2px solid #556ee6;
}
</style>