import axios from "axios"

export const env = (key, defaultValue) => process.env["VUE_APP_" + key] || defaultValue

const serverUrl = env("SERVER_URL")

export const Api = {
    get(route, config, onUploadProgress = null) {
        const token = localStorage.getItem("jwtToken")
        if (token) {
            if (!config)
                config = {}
            if (!config.headers)
                config.headers = {}

            config.headers.Authorization = `Bearer ${token}`
        }
        if (onUploadProgress) {
            config.onUploadProgress = onUploadProgress;
        }
        return axios.get(serverUrl + route, config)
    },

    getWithDataRequest(route, config, data, onUploadProgress = null) {
        const token = localStorage.getItem("jwtToken")
        if (token) {
            if (!config)
                config = {}
            if (!config.headers)
                config.headers = {}

            config.headers.Authorization = `Bearer ${token}`
        }
        if (onUploadProgress) {
            config.onUploadProgress = onUploadProgress;
        }
        return axios.get(serverUrl + route, config, data)
    },

    post(route, data, config, onUploadProgress = null) {
        const token = localStorage.getItem("jwtToken")
        if (token) {
            if (!config)
                config = {}
            if (!config.headers)
                config.headers = {}

            config.headers.Authorization = `Bearer ${token}`
        }

        if (onUploadProgress) {
            config.onUploadProgress = onUploadProgress;
        }
        return axios.post(serverUrl + route, data, config)
    },
    // postFormData(route, data, config) {
    //     return this.post(route, new _FormData(data), config)
    // },

    postFormData(route, data, onUploadProgress = null) {
        const token = localStorage.getItem("jwtToken");
        let config = { headers: {} };

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        if (onUploadProgress) {
            config.onUploadProgress = onUploadProgress;
        }

        const formData = new FormData();
        Object.keys(data).forEach(key => formData.append(key, data[key]));

        return this.post(route, new _FormData(data), config);
    },

    
    put(route, data, config, onUploadProgress = null) {
        const token = localStorage.getItem("jwtToken")
        if (token) {
            if (!config)
                config = {}
            if (!config.headers)
                config.headers = {}

            config.headers.Authorization = `Bearer ${token}`
        }
        if (onUploadProgress) {
            config.onUploadProgress = onUploadProgress;
        }
        return axios.put(serverUrl + route, data, config)
    },

    
    putFormData(route, data, config, onUploadProgress = null) {

        if (onUploadProgress) {
            config.onUploadProgress = onUploadProgress;
        }
        return this.put(route, new _FormData(data), config)
    },


    patch(route, data, config, onUploadProgress = null) {
        const token = localStorage.getItem("jwtToken")
        if (token) {
            if (!config)
                config = {}
            if (!config.headers)
                config.headers = {}

            config.headers.Authorization = `Bearer ${token}`
        }
        if (onUploadProgress) {
            config.onUploadProgress = onUploadProgress;
        }
        return axios.patch(serverUrl + route, data, config)
    },
    patchFormData(route, data, config, onUploadProgress = null) {

        if (onUploadProgress) {
            config.onUploadProgress = onUploadProgress;
        }
        return this.patch(route, new _FormData(data), config)
    },
    
    delete(route, config, onUploadProgress = null) {
        const token = localStorage.getItem("jwtToken")
        if (token) {
            if (!config)
                config = {}
            if (!config.headers)
                config.headers = {}

            config.headers.Authorization = `Bearer ${token}`
        }
        if (onUploadProgress) {
            config.onUploadProgress = onUploadProgress;
        }
        return axios.delete(serverUrl + route, config)
    },
}

export class _FormData extends FormData {
    constructor(data) {
        super()
        for (let key in data) {
            if (Object.hasOwn(data, key)) {
                const value = data[key]
                if (Array.isArray(value))
                    value.forEach(el => this.append(key, el))
                else
                    this.append(key, value)
            }
        }
    }
}
